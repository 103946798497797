:root {
  --dark-blue: #000814;
  --blue: #001D3D;
  --light-blue: #003566;
  --yellow: #FFC300;
  --light-yellow: #FFD60A;
  --dark: #1d1d1d;
  --light: #eeeeee;
}

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4, h5 {
  margin: 0;
  padding-bottom: 1em;
}

.root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
  padding-bottom: 56px;
}

.content {
  flex: 1;
  padding: 20px;
}
